<template>
  <div class="columns is-marginless is-multiline">
    <div class="column is-12 is-4-desktop is-3-widescreen">
      <div class="content has-text-grey has-padding-x-50 has-margin-bottom-50">
        <h2>Site info</h2>
        <p>
          In order for our team of experts to assist you, we'll need you to
          share details about your site and hosting environment.
        </p>
        <p>
          Site notes are perfect for non-sensitive data – use them to drop down
          useful information and we'll do the same.
        </p>
        <p>
          <strong>For anything sensitive or confidential</strong>, we strongly
          advise you share them as site secrets. These are encrypted and
          securely stored until deleted.
        </p>
        <p>
          Our experts can only access site secrets when working on your tasks,
          and a full history of who has decrypted your data can be see in your
          <router-link :to="`/sites/${siteId}/logs`" class="has-text-grey">
            <u>site logs</u> </router-link
          >.
        </p>
      </div>
    </div>
    <div class="column is-12 is-8-desktop is-9-widescreen">
      <div class="columns is-marginless is-multiline">
        <div class="column is-12">
          <collapse title="Site notes">
            <site-notes ref="siteNotes" :site-id="siteId" />
          </collapse>
        </div>

        <div class="column is-12">
          <collapse title="Site secrets">
            <site-secrets ref="siteSecrets" :site-id="siteId" />
          </collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteInfo",
  components: {
    "site-secrets": () => import("@shared/sites/_siteSecrets"),
    "site-notes": () => import("@shared/sites/_siteNotes")
  },
  data() {
    return {
      siteId: this.$route.params.siteId
    };
  }
};
</script>
